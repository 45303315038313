.loginpage_body_parent{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;    
}

.loginpage_body_parent > div {
    background:#F7F6F5;
    padding-top: 25px;
    padding-bottom: 50px;
}

@media only screen and (max-width: 1024px) {
    .loginpage_body_parent{
        display:block;
    }

    .login_card_parent{
        margin-bottom:17px;
        padding-left:30px;
        padding-right:30px;
    }

    .loginpage_body_parent > div {
        padding-bottom:35px;
    }
    .login_main_heading{
        font-size:40px;
    }

    .login_sub_heading{
        font-size:14px;
    }

    .login_button{
        margin-top:10px;
    }
}
