
.slider_innerContainer {
  transition: left 0.8s ease-out;
}

.asdf {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
