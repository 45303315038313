@import '../../styles/scss/index.scss';

.categories {
    &-category {
        grid-template-columns: 5fr 5fr 5fr;
        display: grid;
        @media only screen and (max-width: 1024px) {
             display: block !important;
        }
    }
    &-selected {
        grid-template-columns: 5fr 5fr;
        display: grid;
        @media only screen and (max-width: 1024px) {
            display: block !important;
        }
    }
    &-images {
        min-width: 100%;
        height: inherit;
        cursor: pointer;
        object-fit: cover;
        
        @media only screen and (max-width: 624px) {
            height: 100%;
        }
    }
    &-carousel-container {
        box-sizing: border-box;
        padding-top: 15px;
        height: 100%;
        width: 100%;
    }
    &-extra-padding {
        @media only screen and (max-width: 900px) {
            padding-bottom: 32px;
        }
    }
}

/* Carousel styling */

.react-responsive-modal-modal {
    max-width: 95vw !important;
    height: 98vh;
    margin-top: 0.4rem !important;
    @media only screen and (max-width: 624px) {
        max-width: 95vw !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
.carousel-root {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.carousel-status {
    display: none !important;
}
.carousel .thumb.selected, .carousel .thumb:hover {
    border: 3px solid $primary !important;
}
.carousel .control-dots .dot {
    background: $primary !important;
}
.carousel .slide img {
    // height: 55vh !important;
    height: 100%;
}
.carousel .slide img {
    height: 100%;
}
.slider-wrapper {
    height: 100%;
}
.slider {
    height: 100%;
}
.carousel-slider {
    height: 100%;
    // width: 100%;
}
.control-prev.control-arrow:before {
    border-right-color: grey !important;
}
.control-next.control-arrow:before {
    border-left-color: grey !important;
}