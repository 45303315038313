@import '../../styles//scss/index.scss';

.choose {
    &-container {
        display: flex;
        background-color: $bg-clr;
        padding: 20px 20px;
        @media only screen and (max-width: 640px) {
            display: block;
        }
    }
    &-btn {
        text-align: right;
        float: right;
        margin-left: auto;
        @media only screen and (max-width: 640px) {
            display: block;
            float: none;
            text-align: center;
        }
    }
    &-text {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 640px) {
            display: block;
            float: none;
        }
    }
}