.link-style-class {
  text-decoration: none;
  font-weight: normal;
  font-family: inherit;
  letter-spacing: inherit;
}

.header-top-text {
  a {
    color: #c98368;
    font-size: 12px;

    &:hover {
      color: #FAFAFA !important;
    }
  }
}

.black-header {
  &-text {
    & p {
      & a {
        font-size: 14px !important;
      }
    }
  }
}