.heading_deposit{
    font-size: 35px;
}

.text_deposit{
    font-size: 12px;
}

@media only screen and (max-width: 640px) {
    .heading_deposit{
        font-size: 40px;
    }

    .text_deposit{
        font-size: 16px;
    }

    .options_deposit{
        font-size: 24px;
    }
}

.linkDiv p a {
    font-family: inherit;
    text-decoration: underline;
}