@import '../../styles/scss/index.scss';

.tabs {
   width: 100%;
  .tab {
    width: 33.3%;
    margin-top: 10px;
    font-size: 20px;
    font-family: Montserrat;
    color: $primary;
    text-decoration-line: none;
    display: inline-block;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    @media only screen and (max-width: 640px) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    &.active {
      color: black;
      background: none;
      font-weight: bold;
      border-top: 1px solid rgba(0,0,0,0.2);
      border-right: 1px solid rgba(0,0,0,0.2);
      border-left: 1px solid rgba(0,0,0,0.2);
      border-bottom: none !important;
      @media only screen and (max-width: 640px) {
        border: none;
    }
    }

    }
  .content {
    // padding: 20px;
    color: rgba(0,0,0,0.6);
    background: rgba(255,255,255,0.2)
  }
}