.grid_edit_page{
    display: grid;
}
.grid_2_columns, .mini_grid_2_columns{
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.grid_columns_7_2{
    grid-template-columns: 7fr 2fr;
    grid-gap: 16px;
}

@media only screen and (max-width: 1024px) {
    .edit_body {
        margin-left:0;
        margin-right:0;
    }

    .grid_2_columns, .grid_columns_7_2{
        display:block;
    }

  }