[id="sales-page"] {
  .top-section {
    background: #fafafa 0% 0% no-repeat padding-box;

    .top-text-heading {
      font-size: 35px;
      color: #404040;
      text-align: center;
      height: 48px;
      font: normal normal normal 35px/24px DM Serif Display;
    }

    .top-text {
      text-align: center;
      color: #27292c;
      font: normal normal 300 16px/24px Montserrat;
    }
  }

  .sales-list-top-filter {
    border-bottom: 1px solid #d0ccc671;

    .sort-left {
      width: 83px;
      height: 18px;
      text-align: left;
      font: normal normal normal 14px/24px Montserrat;
      letter-spacing: 0.7px;
      color: #27292c;
    }

    .sort-right {
      text-align: right;

      .item {
        padding: 0 15px;
        width: 25px;
        height: 18px;
        text-align: center;
        font: normal normal bold 14px/24px Montserrat;
        letter-spacing: 0.7px;
        color: #c98468;
      }

      .item-selected {
        padding: 0 15px;
        width: 25px;
        height: 18px;
        text-align: center;
        font: normal normal bold 14px/24px Montserrat;
        letter-spacing: 0.7px;
        color: #27292c;
      }
    }
  }

  .right-total-items {
    text-align: right;
    font: normal normal normal 14px/24px Montserrat;
    letter-spacing: 0.7px;
    color: #bcbcbc;
  }

  .sales {
    .sales-item {
      padding: 15px;
    }

    div:nth-child(3n + 3) {
      .sales-item {
        .item-hover {
          right: 0;
        }
      }
    }
  }

  .sales-dropdown-container {
    box-shadow: 0px 3px 6px #00000029;
  }
}
