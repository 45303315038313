.category {
    &-image-container {
        height: 70vh;
        width: 100%;
    }
    &-image-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        color: white;
        font-size: 146px;
        font-family: 'SerifDisplay';

        @media only screen and (max-width: 910px) {
            font-size: 46px;
        }
    }

    &-background {
        background: #969696 0% 0% no-repeat padding-box;
        height: 65px;
        margin-left: auto;
        margin-right: auto;

        @media only screen and (max-width: 910px) {
            height: 20px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-margin {
        margin-top: -85px;
        margin-left: 45px;
        margin-right: 45px;
        text-align: center;
        max-width: 1100px;

        @media only screen and (max-width: 910px) {
            margin-top: -28px;
            margin-left: 25px;
            margin-right: 25px;
            text-align: center;
            max-width: 325px;
        }
    }

    &-card-text  {
        position: absolute;
        bottom: 15px;
        color: white;
        font-size: 16px;
        font-weight: bold;
    }
}

.linkDiv p a {
    font-family: inherit;
    text-decoration: underline;
}