@import "../../../styles/scss/index.scss";

.product {
  &-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    // width: 50%;
    cursor: pointer;
  }
  &__active__image {
    border: 5px solid $primary;
  }
  &-short-image {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    cursor: pointer;
  }
  &-brand-logo {
    width: 150px;
    height: 150px;
    background-color: #dcd9d4;
    text-align: center;
  }
  &-details-title {
    color: $text-color;
    font-size: 35px;
  }
  &-details-subtitle {
    color: $primary;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: bold;
  }
  &-light-title {
    color: $text-color;
    font-size: 16px;
    font-family: Montserrat;
  }
  &-details-subtitle2 {
    color: $text-color2;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat;
  }
  &-light-title2 {
    color: $text-color2;
    font-size: 16px;
    font-family: Montserrat;
  }
  &-light-title3 {
    color: $text-color;
    font-size: 16px;
    font-weight: bold;
    font-family: Montserrat;
  }
  &-light-title4 {
    color: $text-color;
    font-size: 16px;
    padding-top: 10px;
    font-family: Montserrat;
  }
  &-light-title5 {
    color: $text-color;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat;
  }
  &-light-title6 {
    color: $text-color2;
    font-size: 14px;
    font-weight: bold;
    font-family: Montserrat;
  }
  &-color-title {
    color: $primary;
    font-size: 30px;
    font-weight: bold;
    font-family: Montserrat;
  }
  &-details-images {
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    cursor: pointer;
  }
  &-section {
    border-right: 1px solid $grey-line;
    border-bottom: 1px solid $grey-line;
  }
  &-section2 {
    // border-right: 1px solid $grey-line;
    border-bottom: 1px solid $grey-line;
  }
  &-section3 {
    border-right: 1px solid $grey-line;
    border-top: 1px solid $grey-line;
  }
  &-section4 {
    border-top: 1px solid $grey-line;
  }
  &__slider__left {
    height: 100%;
    width: 40%;
    background-color: transparent;
    position: absolute;
    left: 0;
  }
  &__slider__right {
    height: 100%;
    width: 40%;
    background-color: transparent;
    position: absolute;
    right: 0;
  }
  &__bestel__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-divider {
    background-color: $grey-line !important;
    width: 98% !important;
  }
  &-orange {
    background-color: $grey-line !important;
    width: 100% !important;
  }
  &-green {
    background-color: $grey-line !important;
    width: 100% !important;
  }
}
.table-container {
  height: 210px;
  overflow: auto;
}
.product-table {
  font-family: Montserrat;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: none;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
  }
}
