@import "../../styles/scss/index.scss";
.brands {
  color: #404040;
  background-color: #fafafa;
  &-image {
    display: block;
    margin-right: auto;
    width: 75%;
    cursor: pointer;
  }
  &-checkbox-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &-checkbox-label {
    position: relative;
    bottom: 4px;
    margin-left: 10px;
    color: $text-color;
  }
  &-alphabet-container {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
  }
  &-alphabet-search {
    padding: 8px;
    color: #8d908b;
    cursor: pointer;
    &:hover {
      font-weight: bold;
      font-size: 25px;
    }
  }
  &-alphabet-selected {
    @extend .brands-alphabet-search;
    font-weight: bold;
    font-size: 25px;
    color: $primary !important;
  }
  &-alphabet-list {
    display: block;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}
