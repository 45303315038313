
.section_top {
    background: transparent linear-gradient(180deg, #DCD9D400 0%, #F7F6F5 100%) 0% 0% no-repeat padding-box;
    font-family: 'font-dmserif' 
}

.grid_1_1_footer{
  display: grid;
  margin-left: 50px;
  margin-right: 50px;
  grid-template-columns: 1fr 1fr;
  gap: 0;
}

@media only screen and (max-width: 1024px) {
  .grid_1_1_footer{display: block !important;}
}
.search-footer-top{
    text-align: center;
}

.search-field-footer-top{
  height:40px;
  width:400px;
  border-radius:20px !important;
  background:white;
  box-shadow: 2px 2px 3px #e0e0e0;
  border:none
}

.slider_img:hover{
    filter: grayscale(0%);
}

.slider_img{
    filter: grayscale(100%);
}

.container_slider {
  animation: slide 600s linear infinite;
}


@keyframes slide {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-34000px) /* The image width */
  }
}

@media only screen and (max-width: 1024px) {
  .button_footer_top {
      display: block;
  }    

  .search-field-footer-top{
    width:300px;
  }
}

.search-field {
  background: transparent linear-gradient(175deg, #FFFFFF 0%, #D0CBC4 100%) 0% 0% no-repeat padding-box;
  filter: blur(5px);
}