.products_tabs{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.top_bar_grid{
    background-color: #fafafa;
}

.top_tabs_img {
    filter: brightness(0.9) invert(.7) sepia(.5) hue-rotate(100deg) saturate(200%);
}