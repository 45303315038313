[id="cadeau"] {
  .top-section {
    background: #FAFAFA 0% 0% no-repeat padding-box;

    .top-text-heading {
      font-size: 35px;
      color: #404040;
      text-align: center;
      height: 48px;
      font: normal normal normal 35px/24px DM Serif Display;
    }
  }

  .background-image {
    width: 100%;
    height: 385px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-text-1 {
    text-align: center;
    font: normal normal 300 16px/24px Montserrat;
    letter-spacing: 0px;
    color: #27292C;
    margin: 40px 0;
  }

  .form-text-2 {
    text-align: center;
    font: normal normal 300 16px/24px Montserrat;
    letter-spacing: 0px;
    color: #27292C;
    margin: 10px 0;
  }

  .submit-button {
    margin-top: 10px;
    width: 100%;
  }

}

.linkDiv p a {
  font-family: inherit;
  text-decoration: underline;
}