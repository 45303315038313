.price-slider {
    &-range-one {
        background-color: transparent;
        outline: none; 
        -webkit-appearance: none; 
        appearance: none;
        height: 5px;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        border-radius: 10px;
        pointer-events: none;
        z-index: 5;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 22px; 
            width: 21px;
            background: #B96A4B; 
            cursor: pointer;
            border-radius: 12px;
            pointer-events: all;
        }

        &::-moz-range-thumb {
            height: 22px; 
            width: 22px;
            background: #B96A4B; 
            cursor: pointer;
            pointer-events: all;
        }
    }

    &-range-two {
        background-color: transparent;
        outline: none; 
        -webkit-appearance: none; 
        appearance: none;
        height: 5px;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        border-radius: 10px;
        pointer-events: none;
        z-index: 4;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 22px; 
            width: 21px;
            background: #B96A4B; 
            cursor: pointer;
            border-radius: 12px;
            pointer-events: all;
        }

        &::-moz-range-thumb {
            height: 22px; 
            width: 22px;
            background: #B96A4B; 
            cursor: pointer;
            pointer-events: all;
        }
    }

    &-input {
        -moz-appearance: textfield;
        
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}