@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: italic;
  src: local('Montserrat'),
  url(../assets/fonts/montserrat/Montserrat-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  font-style: italic;
  src: local('Montserrat'),
    url(../assets/fonts/montserrat/Montserrat-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'SerifDisplay';
  font-weight: 400;
  src: local('SerifDisplay'),
    url(../assets/fonts/serif/DMSerifDisplay-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SerifDisplay';
  font-weight: 400;
  font-style: italic;
  src: local('SerifDisplay'),
    url(../assets/fonts/serif/DMSerifDisplay-Italic.ttf) format('truetype');
}