.top_bar_grid{
    background:#fafafa;
}

@media only screen and (max-width: 1024px) {
    .top_bar_grid{
        background:white;
        
    }    
    .top_bar_parent{
        width:80%;
    }
}

