.circle {
    height: 100%;
    position: relative;
    top: 7px;
    &-btn {
        border-radius: 50%;
        height: 10px;
        width: 10px;
        display: block;
    }
}