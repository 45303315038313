[id="inventory-card"] {
  .inventory-item {
    cursor: pointer;
    height: 125px;
    position: relative;

    &:hover {
      .item-hover {
        display: block;
      }

      .item {
        display: none;
      }
    }

    .item {
      background-color: #ffffff;
    }

    .item-hover {
      display: none;
      border: 1px solid #c98468;
      margin-bottom: 0;
      position: absolute;
      background: #fff;
      box-shadow: 2px 2px 10px 2px #b8b8b89e;
      bottom: 0;
      width: 430px;
      height: 200px;
      z-index: 1;
    }
  }
  .description-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .second_name_text {
    display: block;
    font-size: 14px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 2px;
    width: '60%';
  }
}

.price {
  color: #c98468 !important;
  font-family: Montserrat;
  font-weight: 500;
  // margin-left: 10px;
}

.overflow-break {
  overflow-wrap: break-word;
}
