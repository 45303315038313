[id="cookies-page"] {
  .top-section {
    background: #FAFAFA 0% 0% no-repeat padding-box;

    .top-text-heading {
      font-size: 35px;
      color: #404040;
      text-align: center;
      font: normal normal normal 35px/24px DM Serif Display;
    }

    .top-text {
      text-align: center;
      color: #27292C;
      font: normal normal 300 16px/24px Montserrat;
    }
  }

  .inventory-list-top-filter{
    border-bottom: 1px solid #D0CCC671;

    .sort-left {
      width: 83px;
      height: 18px;
      text-align: left;
      font: normal normal normal 14px/24px Montserrat;
      letter-spacing: 0.7px;
      color: #27292C;
    }

    .sort-right {
      text-align: right;

      .item {
        padding: 0 15px;
        width: 25px;
        height: 18px;
        text-align: center;
        font: normal normal bold 14px/24px Montserrat;
        letter-spacing: 0.7px;
        color: #c98468;
      }

      .item-selected {
        padding: 0 15px;
        width: 25px;
        height: 18px;
        text-align: center;
        font: normal normal bold 14px/24px Montserrat;
        letter-spacing: 0.7px;
        color: #27292C;
      }
    }
  }

  .right-total-items {
    text-align: right;
    font: normal normal normal 14px/24px Montserrat;
    letter-spacing: 0.7px;
    color: #BCBCBC;
  }

  .inventory {
    .inventory-item {
      padding: 15px;
    }

    div:nth-child(3n + 3) {
      .inventory-item {
        .item-hover {
          right: 0;
        }
      }
    }
  }

  .inventory-dropdown-container {
    box-shadow: 0px 3px 6px #00000029;
  }

  .text-heading {
    font-size: 35px;
    color: #404040;
    line-height: normal !important;
    font: normal normal normal 35px/24px DM Serif Display;
  }

  .text-text {
    text-align: left;
    font: normal normal 300 16px/24px Montserrat;
    letter-spacing: 0.8px;
    color: #27292C;
  }

  .sub-head {
    text-align: left;
    font: normal normal 600 20px/24px Montserrat;
    letter-spacing: 1px;
    color: #C98468;
  }

  .small-heading {
    text-align: left;
    font: normal normal bold 16px/24px Montserrat;
    letter-spacing: 0px;
    color: #27292C;
  }
}
