
.products_tabs{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.btn_verder_basket_mb {
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
    
    &:hover {
        background-color: #B96A4B;
        color: white;
    }
}

.table_row_grid{
    display:grid;
    grid-template-columns:112fr 150fr 72fr 50fr 64fr 70fr 30fr;
}

.basket_drop_down {
    border:1px solid #D0CCC6;
    border-radius: 3px;
    color: #27292C;
    font-size: 13px;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%23C98468'/></g></svg>") no-repeat;

    @media (max-width: 700px) {
        margin-bottom: 0px;
    }
}

.bdd_qty{
    background-position: right 5px top 60%;
    padding:10px 30px 10px 25px;

    @media (max-width: 700px) {
        padding: 5px 5px;
    }
}

.total_basket_amount{
    display:grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1024px) {
    .table_row_grid{
        display:block;
        margin-bottom: 30px;
    }

    .tab_content_basket{
        margin-top: 0px;
    }

    .margin_top_basket_10px{
        margin-top: 10px;
    }

    .price_basket{
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .drop_down_basket{
        margin-left: 0px;
    }

    .total_price_basket{
        float: right;
        margin-top: 10px;
    }

    .delete_btn_basket{
        // float: right;
    }

    .row_total_amount_basket{
        padding:0;
    }

    .row_total_parent_basket{
        padding:20px;
    }

    .btn_basket_mb{
        margin-top:30px;
        margin-bottom:10px;
        margin-right: 30%;
        margin-left: 30%;
        float: none;
    }

    .btn_verder_basket_mb{
        display: block;
        // width: 100%;
        margin-bottom:10px;
        margin-right: 0;
    }

    .btn_bestellen_basket_mb{
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .btn_basket_mb{
        margin-right: 0px;
        margin-left: 0px;
        display: flex;
        justify-content: space-between;
    }

}