[id="promotions-card"] {
  background: #F7F6F5 0% 0% no-repeat padding-box;
  border-radius: 2px 2px 0px 0px;

  .title-text {
    text-align: left;
    font: normal normal normal 24px/41px DM Serif Display;
    letter-spacing: 0px;
    color: #27292C;
  }

  .description-text {
    font: normal normal normal 14px/22px Montserrat;
    letter-spacing: 0.9px;
    color: #404040;
  }

  .bottom-text {
    text-align: left;
    font: normal normal 600 13px/24px Montserrat;
    letter-spacing: 0px;
    color: #404040;
  }
}

.linkDiv p a {
  font-family: inherit;
  text-decoration: underline;
}