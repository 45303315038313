[id="footer-lower"] {
  background-color: #27292c !important;
  width: 100%;
  padding: 20px 150px;
  color: #c98468;

  @media only screen and (max-width: 960px) {
    padding: 20px 10px;
  }

  .section-1 {
    .item {
      padding-top: 20px;
      border-right: 1px solid #ececec61;
      border-bottom: 1px solid #ececec61;

      &:last-child {
        border-right: none;
        font-family: SerifDisplay;
      }

      .button-wrapper {
        text-align: center;
        width: 100%;

        .button-1 {
          background: #c98468;
          padding: 6px 30px;
          border-radius: 24px;
          color: white;
          font-weight: 400;
        }
      }

      .social-media {
        padding: 10px 10px 30px;
        img {
          margin-right: auto;
          margin-left: auto;
        }
      }

      .email-input {
        border-radius: 20px;
        line-height: 32px;
        width: 100%;
        padding-left: 15px;
      }
    }
  }

  .search-field-footer-lower{
    height:40px;
    border-radius:20px !important;
    background:white;
    border:none
  }

  .image-rooftop {
    margin-left: auto;
    margin-right: auto;
  }

  .logo-interier {
    margin-right: auto;
    margin-left: auto;
  }

  .no-margin-bottom {
    margin-bottom: 0 !important;
  }

  .section-2 {
    margin-top: 20px;
    padding-bottom: 20px;

    .item {
      text-align: center;

      .timing {
        color: #ececec;
        font-family: Montserrat;
        font-weight: 350;

        img {
          margin-left: auto;
          margin-right: auto;
        }

        .help-section {
          display: flex;

          .help-heading-text {
            display: contents;
          }

          .help-image {
            display: contents;

            img {
              margin: 0 0 0 10px;
            }
          }
        }
      }

      .heading {
        font-size: 19px;
        font-family: SerifDisplay;
        color: #c98468;
      }

      .help-heading {
        white-space: nowrap;
      }

      .link {
        color: #c98468;
        text-decoration: underline;
      }
    }
  }

  .section-3 {
    margin-top: 20px;
    border-top: 1px solid #ececec61;

    .item {
      text-align: center;

      .timing {
        color: #ececec;
        font-family: Montserrat;
        font-weight: 350;

        .rooftop-head {
          font-family: SerifDisplay;
          color: #baab78;
          font-weight: 600;
          font-size: 28px;
        }

        .sub-head {
          color: #baab78;
        }
      }

      .heading {
        font-size: 19px;
        font-family: SerifDisplay;
        color: #c98468;
      }

      .help-heading {
        white-space: nowrap;
      }

      .link {
        color: #c98468;
        text-decoration: underline;
      }
    }
  }

  .footer-mobile {
    .section-1 {
      border-bottom: 1px solid #ececec61;
    }

    .item {
      border: none;

      .timing {
        .sub-head {
          color: #baab78;
        }
      }

      .image-mobile-center {
        margin-left: auto;
        margin-right: auto;
      }

      .div-center-border {
        width: 1px;
        border: 1px solid #ececec61;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
      }
    }
  }
}

.item-text {
  a {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.8px;
    cursor: pointer;
    color: #c98468;
    text-decoration: underline;

    &:hover {
      color: #1da2b3 !important;
    }
  }
}

.item-text-footer {
  font-size: 14px;
  letter-spacing: 0.8px;
  cursor: pointer;
  color: #ececec;

  a {
    font-size: 14px;
    letter-spacing: 0.8px;
    cursor: pointer;
    color: #ececec;
    text-decoration: none;

    &:hover {
      color: #c98468 !important;
    }
  }

}
