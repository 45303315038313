@import "../../styles/scss/index.scss";

.button-wrapper {
    display: inline-block;
    .button-1 {
      background: #c98468;
      padding: 6px 30px;
      border-radius: 24px;
      color: white;
      font-weight: 400;
      font-family: 'montserrat';
      @media only screen and (max-width: 640px) {
        margin-top: 10px;
      }
    }
    .button-2 {
      background: white;
      padding: 6px 30px;
      border-radius: 24px;
      color: $secondary;
      font-weight: 400;
      @media only screen and (max-width: 640px) {
        margin-top: 20px;
      }
    }
}