.table_row_grid_account_page{
    display:grid;
    grid-template-columns:112fr 120fr 50fr 50fr 50fr 70fr;
}

.info_icon_parent_acc_page_mb{
    display: none;
}

.grid_3_1_acc_page_mb{
    display:block;
}

.grid_8_1{
    display:grid;
    grid-template-columns: 3fr 1fr;
    gap: 11px;
}

.vertical_center_acc_page {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid_account_page{
    display: grid;
}
.grid_2_columns, .mini_grid_2_columns{
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

.grid_columns_7_2{
    grid-template-columns: 7fr 2fr;
    grid-gap: 16px;
}

@media only screen and (max-width: 1024px) {
    .account_body {
        margin-left:0;
        margin-right:0;
    }

    .grid_2_columns, .grid_columns_7_2{
        display:block;
    }

  }

@media only screen and (max-width: 1024px) {
    .table_row_grid_account_page{
        display: block;
        padding:20px !important;
        // background: white;
    }

    .img_acc_page_mb{
        margin:0;
    }

    .heading_acc_page_mb{
        margin-top: 10px;
        padding-left: 0px;
    }

    .code_acc_page_mb{
        margin-top: 10px !important;
        text-align: left !important;
    }

    .price_acc_page_mb{
        margin-top: 10px !important;
        text-align: left !important;
    }

    .qty_acc_page_mb{
        margin-top: 10px !important;
        text-align: left !important;
        margin-left: 0px !important;
    }

    .total_price_acc_page_mb{
        margin-top: 10px !important;
        margin-bottom: 5px;
        text-align: right !important;
    }

    .grid_8_1{
        display: block;
    }

    .text_acc_page_mb{
        display:none;
    }

    .info_icon_acc_page_mb{
        display: inline;
        float:right;
    }

    .info_icon_parent_acc_page_mb{
        display: block;
    }
    
    .grid_3_1_acc_page_mb{
        display:grid;
        grid-template-columns: 1fr 1fr;
    }
}
  
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }

  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup_account_page_mb {
    margin: 70px auto;
    padding: 20px;
    background: #C98468;
    border-radius: 5px;
    color: white;
    margin-top: 50%;
    width: 90%;
    font-family: Montserrat;
    position: relative;
    transition: all 5s ease-in-out;
  }
  
  .popup_account_page_mb .close {
    position: absolute;
    top: 10px;
    right: 8px;
    transition: all 200ms;
    font-weight: bold;
    text-decoration: none;
    color: white;
  }

  .content{
    //   margin: 15%;
      font-weight: bold;
  }