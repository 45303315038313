.fs-10 { font-size: 10px;}
.fs-11 { font-size: 11px;}
.fs-12 { font-size: 12px;}
.fs-13 { font-size: 13px;}
.fs-14 { font-size: 14px;}
.fs-15 { font-size: 15px;}
.fs-16 { font-size: 16px;}
.fs-17 { font-size: 17px;}
.fs-18 { font-size: 18px;}
.fs-19 { font-size: 19px;}
.fs-20 { font-size: 20px;}
.fs-21 { font-size: 21px;}
.fs-22 { font-size: 22px;}
.fs-23 { font-size: 23px;}
.fs-24 { font-size: 24px;}
.fs-25 { font-size: 25px;}
.fs-26 { font-size: 26px;}
.fs-27 { font-size: 27px;}
.fs-28 { font-size: 28px;}
.fs-29 { font-size: 29px;}
.fs-30 { font-size: 30px;}
.fs-31 { font-size: 31px;}
.fs-32 { font-size: 32px;}
.fs-33 { font-size: 33px;}
.fs-34 { font-size: 34px;}
.fs-35 { font-size: 35px;}
