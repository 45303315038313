.collectie-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    justify-content: center;
  }
}


.collectie-merken-container {
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
}