@import "../../../styles//scss/index.scss";

.application {
  &-main-container {
    position: relative;
    padding: 0px 100px;
    @media only screen and (max-width: 624px) {
      padding: 0px;
    }
  }
  &-container {
    display: block;
    background-color: $bg-clr;
    border: 3px solid white;
  }
  &-back-page {
    color: $link-clr;
    text-decoration-line: underline;
  }
  &-form-section {
    text-align: center;
    padding: 0px 120px;
    @media only screen and (max-width: 624px) {
      padding: 0px;
    }
  }
}

.grid_2_columns,
.mini_grid_2_columns {
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  display: grid;
}

.grid_columns_7_2 {
  grid-template-columns: 7fr 2fr;
  grid-gap: 16px;
}

// .grid-container {
//   display: grid;
//   grid-template-columns: 4fr 4fr;
//   grid-gap: 16px;
// }

@media only screen and (max-width: 1024px) {
  .register_body {
    margin-left: 0;
    margin-right: 0;
  }

  .grid_2_columns,
  .grid_columns_7_2 {
    display: block;
  }
}

.linkDiv p a {
  font-family: inherit;
  text-decoration: underline;
}