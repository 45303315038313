.left_action_padding_div {
  transform: translate(0, -50%);
}

.left_action {
  //   background: rgb(163, 52, 0, 0.4) 0% 0% no-repeat padding-box;
}

.left_action_heading {
  font-size: 30px;
}

.left_action_button_div {
  position: relative;
  transition: 0.5s;
  display: block;
  flex: 0 !important;
}

// .left_action_button_div:hover {
//     right:-20px;
//     top:10px;
// }
