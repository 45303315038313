.information_heading{
    font-family: SerifDisplay;
}

.information_card_parent{
    font-family: Montserrat;
}

.information_card_row_1_1{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
}

@media only screen and (max-width: 1024px) {

    .information_card_row_1_1{
        display:block;
    }

    .information_card_parent{
        margin-bottom:20px;
    }

}