
.bs-wizard {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {

}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #fff; 
    font-size: 16px;
    margin-bottom: 5px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #C98468; font-size: 12px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: white; border-color:#C98468;border-width:1px; top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ''; width: 16px; height: 16px; background: #C98468; border-radius: 100%; position: absolute; top:6px; left: 6px; } 
.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    background:#C98468;
    border-radius: 0px;
    height: 1px;
    box-shadow: none;
    margin: 20px 0;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background:#C98468;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: white; border-color: #C98468; border-width:1px;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }


 @media only screen and (max-width: 1024px) {
    .bs-wizard {
        margin-bottom:80px;
    }
}