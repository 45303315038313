.grid_4_favorite_items{
    grid-template-columns: repeat(4, 1fr);
    display:grid;
    gap: 16px;
}

@media only screen and (max-width: 1024px) {
    .grid_4_favorite_items{
        display:block;
        margin-top: 0px !important;
    }
}