  
  .custom_radio input[type="radio"]{
    display: none;
  }
  
  .custom_radio input[type="radio"] + label{
    position: relative;
    cursor: pointer;
    padding-left: 1.5em;
    margin-right: 2em;
    line-height: 1em;
  }

  
  .custom_radio input[type="radio"] + label:before,
  .custom_radio input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
  }
  .custom_radio input[type="radio"] + label:before {
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
    border:1px solid #C98468;
  }
  
  .custom_radio input[type="radio"]:checked + label:before {
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #C98468;
    border:1px solid #C98468;
  }
  .mobile-long-text{
    display:none;    
  }

.pr_radio_group{
  padding-right: 112px;
}

@media only screen and (max-width: 640px) {
  .custom_radio{
    // margin: 0 10%;
    align-items: center;
    // justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .mobile-space-show{
    display:none;
  }

  .pr_radio_group{
    padding-right: 60px;
  }

}

@media only screen and (max-width: 900px) {
  .mobile-long-text{
    display:flex;
    margin-bottom:20px;
  }

  .custom_radio_mobile_long_text{
    margin-right:0 !important;
    text-align: left;
  }
}

.custom_radio label::before{
    // margin-left:-10px;
    // margin-top: -5px;
}

