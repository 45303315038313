
.container{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.label-text{
  cursor: pointer; 
}
  
  // input[type="checkbox"] + .label-text:before{
  //   display: inline-block;
  //   transition: all 0.2s ease-in-out;
  //   margin: -4px 15px 0 0;
  //   vertical-align: middle;
  //   width: 18px;
  //   height: 18px;
  //   border: 2px solid #a9aaa8;
  //   border-radius: 3px;
  //   content: '';
  // }
    
  // input[type="checkbox"]:checked + .label-text:before{
  //   background: white;
  //   text-align: center;
  //   font-weight: bold;
  //   cursor:pointer;
  //   line-height: 1em;
  //   font-size:12px;
  //   color: #C98468;
  //   content: '\2714';
  // }

.input-check-error:before {
  border: solid 2px red !important;
}

.checkbox-unchecked{
  display: inline-block;
  transition: all 0.2s ease-in-out;
  // margin: -4px 15px 0 0;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border: 2px solid #a9aaa8;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    content: '';
  }
}
    
.checkbox-checked {
  background: white;
  text-align: center;
  cursor:pointer;
  border: 2px solid #a9aaa8;
  border-radius: 3px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content:before {
    font-weight: bold;
    line-height: 1em;
    font-size:12px;
    color: #C98468;
    content: '\2714';
  }
}

@media only screen and (max-width: 640px) {
  input[type="checkbox"]:checked + .label-text:before{
    font-size:16px;
  }  
}
