.items_parent {
  display: grid;
  grid-template-columns: 5fr 6fr 4fr;
}

@media only screen and (max-width: 1024px) {
  .items_parent {
    display: block;
  }
}
