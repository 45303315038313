@import "../../styles/scss/index.scss";
.page {
	float: right;
	&-list {
		display: flex;
		list-style: none;
	}
	&-item {
		text-align: center;
		font: normal normal bold 14px/24px Montserrat;
		letter-spacing: 0.7px;
		a.page-link {
			padding-left: 10px;
			text-decoration-line: none;
			color: $primary;
		}
		a.page-link.disabled-link {
			pointer-events: none;
			color: #ccc;
			display: none;
		}
		a.page-link.active-link {
			cursor: pointer;
			color: $primary;
		}
		a.page-link.disabled {
			pointer-events: none;
			color: $primary !important;
		}
	}
	.active {
		text-align: center;
		font: normal normal bold 14px/24px Montserrat;
		letter-spacing: 0.7px;
		color: red !important;
		a.page-link {
			color: $text-color2;
		}
	}
}
