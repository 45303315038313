@import "../../styles//scss/index.scss";

.category-details {
  &-container {
    background-color: $light-grey;
    padding-bottom: 20px;
    min-width: 100%;
  }
  &-top-heading {
    font-size: 35px;
    color: #404040;
    text-align: center;
    font: normal normal normal 35px/24px DM Serif Display;
  }
  &-top-text > p {
    text-align: center;
    color: #27292c;
    font: normal normal 300 16px/24px Montserrat;
    @media only screen and (max-width: 640px) {
      padding: 0px 15px;
    }
  }
}
