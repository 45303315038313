a {
  text-align: left;
  text-decoration: underline;
  font: normal normal 300 16px/24px Montserrat;
  letter-spacing: 0.8px;
  color: #27292C;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    color: #27292C !important;
  }
}

a:hover {
  color: #1da2b3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.app {
  padding: 12px;
}

.breadcrumb-separator {
  display: flex;
  align-items: center;
  color: #333;
  margin: 0px 6px;
  user-select: none;
}

.breadcrumb-collapser {
  display: flex;
  align-items: center;
}

.breadcrumb-collapser svg {
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: #000;
  width: 25px;
  height: 25px;
}

.breadcrumb-collapser svg:hover,
.breadcrumb-collapser svg:focus {
  color: #999;
  transform: scale(1.15);
}

.breadcrumb-collapser svg:active {
  text-align: left;
  font: normal normal 600 13px/24px Montserrat;
  letter-spacing: 0.8px;
  color: #27292C;
}

.some-custom-classname {
  display: flex;
  align-items: center;
}

.some-custom-classname svg {
  color: #000;
  opacity: 0.75;
  margin-right: 3px;
}

.active-breadcrumb-item {
  text-align: left;
  font: normal normal 600 13px/24px Montserrat;
  letter-spacing: 0.8px;
  font-weight: bold;
  color: #27292C;
  font-size: 16px;
}
