.right_action_child{
    transform: translate(0, -50%);
}

@media only screen and (max-width: 1024px) {
    
    .right_action_heading{
        font-size:34px;
        line-height:35px;
    }

    .right_action_button{
        padding:7px 55px;
    }

    .right_action_button_div{
        position: relative;
        transition: 0.5s;
    }
    
    .right_action_button_div:hover{
        left:-20px;
    }
    
    .right_action_child{
        transform:none;
        padding:7% 0 10% 0;
    }
}

