.grid_1_1_1{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
}

@media only screen and (max-width: 1024px) {
    .grid_1_1_1{
        display:block;
    }
    .pickup_card_parent{
        text-align: center;
        margin-bottom:15px;
    }

    .pickup_card_parent img{
        height: auto;
        width: 100%;
    }
}

