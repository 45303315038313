@import '../../styles/scss/index.scss';
.dropdown {
  position: relative;
  background: $light-grey;
  border-radius: 1px;
  width: 55vw;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  
  &__list {
    transition: max-height .6s ease-out;
    max-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    
    &--active {
      max-height: 1000px;
      opacity: 1;
    }
  }
    
  &__list-item {
    cursor: pointer;
    list-style: none;    
    padding: 15px;
    
    &--active {
      background: #f4f4f4;
    }
    
    &:hover {
      background: #f4f4f4;
    }
  }
  &__d-none {
    display: none !important;
  }
}