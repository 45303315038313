.left_action_padding_div {
  transform: translate(0, -50%);
}
@media only screen and (max-width: 1024px) {
  .left_action {
    // background:rgb(163, 52, 0, 0.4);
    margin-bottom: 20px;
  }

  // .left_action_heading {
  //   font-size: 48px !important;
  // }

  .left_action_button_div {
    position: relative;
    transition: 0.5s;
  }
}
