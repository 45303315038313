@import '../../../styles//scss/index.scss';

.jobs {
    &-container {
        background-color: $light-grey;
    }
    &-bottom-image-container {
        display: flex;
        padding-bottom: 10px;
        
        img {
            width: 30%;
            height: 30%;
            @media only screen and (max-width: 640px) {
                width: 100%;
                display: block;
            }
        }
        @media only screen and (max-width: 640px) {
            display: block;
            width: 100%;
            text-align: center;
        }
    }
    &-text-logo {
        margin: auto;
        margin-left: 50px;
        font-size: 16px;
        color: $text-color2;
        @media only screen and (max-width: 640px) {
            margin: 0px !important;
        }
    }
    &-checkbox-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    &-table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        overflow-x: scroll;

    td, th {
        border: 3px solid white;
        padding: 8px;
    }

    tr td:nth-child(4) {
        width: 250px;
        padding: 0px !important;
    }

    td {
        background-color: #F7F6F5;
        border-bottom: 15px solid white;
        padding: 20px 50px 50px 20px;
    }

    th {
        padding: 12px;
        text-align: left;
        background-color: $primary;
        color: white;
        font-size: 25px;
        font-family: 'DM Serif Display';
      }
    }

    .no-pad {
        padding: 0px !important;
    }
}

.linkDiv p a {
    font-family: inherit;
    text-decoration: underline;
}