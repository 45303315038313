.grid_1_1{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-bottom: 15px;
}

.grid_5_1{
    display:grid;
    grid-template-columns: 3fr 1fr;    
    font-family: Montserrat;
}

@media only screen and (max-width: 1024px) {    
    .mobile-padding{
        margin-bottom:20px !important;
    }
    
    .mobile-padding-button{
        margin-top: 10px;
        font-size: 14px;
        width: 130px;
    }

    .grid_5_1{
        display:flex;
        // grid-template-columns: 3fr 1fr;   
        flex-direction: column; 
        font-family: Montserrat;
    }
    
}


@media only screen and (max-width: 640px) {
    .grid_1_1{
        display: block;
    }   
}