
.maakeenafspraak_sticky_button {
    border-radius: 5px 5px 0px 0px;
    transform: rotate(-90deg);
    height: 40px;
    min-width: 210px;
    margin-top: -20px;
    right: -85px;
    top: 50%;
    position: fixed;
}

@media only screen and (max-width: 1024px) {

    .maakeenafspraak_sticky_button{
        border-radius: 100%;
        transform: none;
        min-width:53px;
        height:53px;
        bottom:0;
        top:auto;
        right:0;
        padding:10px 0 0 8px;
        margin:0 10px 10px 0;
    }

    .chat_icon{
        transform: scale(1.5);
    }

    .maakeenafspraak_sticky_button p{
        display:none;

    }
}
