  
  .custom_radio input[type="radio"]{
    display: none;
  }
  
  .custom_radio input[type="radio"] + label{
    position: relative;
    cursor: pointer;
    padding-left: 1.5em;
    margin-right: 2em;
    line-height: 1em;
  }
  .custom_radio input[type="radio"] + label:before,
  .custom_radio input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    color: white;
    border-radius: 50%;
  }
  .custom_radio input[type="radio"] + label:before {
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
    border:1px solid #C98468;
  }
  
  .custom_radio input[type="radio"]:checked + label:before {
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #C98468;
    border:1px solid #C98468;
  }

@media only screen and (max-width: 640px) {
    .afspraak_form_content{
        margin-left: 1%;
        margin-right:1%;
    }
}
.custom_radio label::before{
    margin-left:-10px;
    margin-top: -5px;
}

.appointmentDiv p a {
  font-family: inherit;
  text-decoration: underline;
}