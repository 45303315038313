.grid_3{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 100px;
}

.back_button_submit{
    text-align:right;
    margin-top: 10px;
    font-size: 16px;
}

@media only screen and (max-width: 1024px) {
    .grid_3{
        display:block;
    }
    
    .back_button_submit{
        margin-bottom:40px;
        text-align:center;
        font-size: 21px;
    }
}
