@import "../../styles/scss/index.scss";

.cookie {
    &-container {
        position: fixed;
        bottom: 0%;
        left: 50%;
        width: 80% !important;
        transform: translate(-50%, 0%);
        background-color: $secondary;
        color: white;
        padding: 20px  50px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        opacity: 0.9;
        z-index: 99 !important;
        @media only screen and (max-width: 640px) {
            height: 100%;
            border-radius: 0px;
            text-align: justify;
            width: 100% !important;
            overflow-y: scroll;
            z-index: 999 !important;
        }
    }
    &-main-title {
        text-align: center;
        @media only screen and (max-width: 640px) {
            text-align: left;
            white-space: nowrap;
        }
    }
    &-section {
        display: flex;
        padding: 10px 0px;
        @media only screen and (max-width: 640px) {
            display: block;
        }
    }
    &-btn-container {
        text-align: right;
        @media only screen and (max-width: 640px) {
            text-align: center;
        }
    }
    &-close-image {
        float: right;
        cursor: pointer;
        @media only screen and (max-width: 640px) {
            position: absolute;
            right: 10px;
            top: 20px;
        }
    }
    &-cookie-image {
        width: 58px;
        height: 58px;
        @media only screen and (max-width: 640px) {
            width: 100%;
        }
    }
    &-clock-image {
        width: 55.5px;
        height: 55.5px;
        @media only screen and (max-width: 640px) {
            width: 100%;
        }
    }
    &-btn-1 {
        border: 1px solid white !important;
        background-color: $secondary !important;
    }
    &-link {
        color: white;
        text-decoration-line: underline;
        cursor: pointer;
    }
    &-para-padding {
        padding-left: 30px;
        @media only screen and (max-width: 640px) {
        padding-left: 0px;
        }
    }
}