.top-section {
    background: #FAFAFA 0% 0% no-repeat padding-box;

    .top-text-heading {
      font-size: 35px;
      color: #404040;
      text-align: center;
      height: 48px;
      font: normal normal normal 35px/24px DM Serif Display;
    }
}