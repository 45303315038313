
.basket_drop_down {
    border-radius: 3px;
    font-size: 16px !important;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: white url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%23C98468'/></g></svg>") no-repeat;
    margin-bottom: 15px;
}

.input_select_component select:required:invalid {
    color: #8D908B;
}

.input_select_component option[value=""][disabled] {
    display: none;
}

.input_select_component  option {
    color: #404040;
}

.bdd_qty{
    background-position: right 5px top 60%;    
}

.p_singular{
    padding:10px 30px 10px 25px;
}

.p_group{
    padding: 8px 30px 8px 15px !important;
}

.border_color_redOrange{
    border:1px solid #B96A4B !important;
}

.border_color_gray{
    border-width: 2px !important;
}